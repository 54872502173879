import React from 'react'
import {
  EdlynLogo,
  WoodsBasicLogo,
  WoodsLogo,
  GreenWheatLogo,
  BoneLogo,
  ChefHat,
  Coffee,
  International,
  RightArrow,
  BoneLogoWhite,
  BoneBasicLogo,
  Story,
  Team,
  News,
  Recipes,
  Catalogues,
  Careers,
  EdlynMobileYears
} from './icons'
import {graphql, useStaticQuery} from "gatsby";
import { Link } from "../../plugins/gatsby-plugin-atollon"
import {ChevronRight, ChevronLeft, Edlyn1929, Edlyn2024} from "./icons";
import AnimationButton from "./structured-text/blocks/animation-button";

const Header = ({ header, brand }) => {
  const [offCanvas, setOffCanvas] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)
  const [offCanvasMenu, setOffCanvasMenu] = React.useState({
    subActive: false,
    menu: [],
    initial: false,
  })
  const [dropdownMenu, setDropdownMenu] = React.useState({
    dropdownActive: false,
    menu: [],
    initial: false,
  })
  const [brandsMenu, setBrandsMenu] = React.useState(false)
  const [servicesMenu, setServicesMenu] = React.useState(false)
  const [aboutMenu, setAboutMenu] = React.useState(false)
  const [newsMenu, setNewsMenu] = React.useState(false)
  const [direction, setDirection] = React.useState('down')


  const closeMenu = () => {
    setOffCanvasMenu({
      subActive: false,
      menu: [],
      initial: false,
    })
    setDropdownMenu({
      dropdownActive: false,
      menu: [],
      initial: false,
    })
    setOffCanvas(false)
  }

  const openBrandMenu = () => {
    setBrandsMenu(true)
  }
  const closeBrandMenu = () => {
    setBrandsMenu(false)
  }
  const openNewsMenu = () => {
    setNewsMenu(true)
  }
  const closeNewsMenu = () => {
    setNewsMenu(false)
  }

  const openOffCanvasMenu = (i) => {
    offCanvasMenu.menu[i] = true
    setOffCanvasMenu({
      ...offCanvasMenu,
      subActive: true,
      initial: true,
    })
  }

  const closeOffCanvasMenu = (i) => {
    setOffCanvasMenu({
      subActive: false,
      menu: [],
      initial: false,
    })
  }

  const openAboutMenu = () => {
    setAboutMenu(true)
    setScrolled(true)
  }

  const closeAboutMenu = () => {
    setAboutMenu(false)
    setScrolled(false)
  }

  const openExpertiseMenu = () => {
    setServicesMenu(true)
    setScrolled(true)
  }

  const closeExpertiseMenu = () => {
    setServicesMenu(false)
    setScrolled(false)
  }

  let isScrolling
  let prevScroll = 0

  const onScroll = (e) => {
    let scr, hhScrolled

    const curScroll = window.scrollY || document.scrollTop
    scr = curScroll > 1
    let dir = curScroll > prevScroll ? 'down' : 'up'

    // Make sure they scroll more than 5px
    if (Math.abs(prevScroll - curScroll) <= 5) return

    prevScroll = curScroll

    requestAnimationFrame(() => {
      setScrolled(scr)
      setDirection(dir)
    })
  }

  const scrollDetect = () => {
    window.clearTimeout(isScrolling)
    isScrolling = setTimeout(onScroll, 0)
  }

  const offCanvasMenuClick = (key) => {
    let { subActive, menu, initial } = offCanvasMenu
    subActive = false
    initial = false

    if (offCanvasMenu.menu[key]) {
      setOffCanvasMenu({
        subActive: false,
        menu: [],
        initial: false,
      })
      return
    }

    if (menu[key]) {
      menu[key] = true
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }

    subActive = true

    setOffCanvasMenu({ initial, menu, subActive })
  }

  const dropdownToggle = (key) => {
    let { dropdownActive, menu, initial } = dropdownMenu
    dropdownActive = false
    initial = false

    if (dropdownMenu.menu[key]) {
      setDropdownMenu({
        dropdownActive: false,
        menu: [],
        initial: false,
      })
      return
    }

    if (menu[key]) {
      menu[key] = true
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }

    dropdownActive = true

    setDropdownMenu({ initial, menu, dropdownActive })
  }

  React.useEffect(() => {
    window.addEventListener('scroll', scrollDetect, false)
    return () => {
      window.removeEventListener('scroll', scrollDetect, false)
    }
  }, [])

  let props = {
    onClick: () => closeMenu(),
    activeClassName: 'active',
  }

  let headerClass = 'header'
  if ( scrolled || brandsMenu || aboutMenu || servicesMenu || newsMenu || offCanvas ) headerClass += ' header--scrolled'
  if ( offCanvas ) headerClass += ' header--off-canvas'
  if ( dropdownMenu.dropdownActive ) headerClass += ' header--dropdown-active'
  if ( brand ) headerClass += ` ${brand}`
  if ( header ) headerClass += ` header--${header}`
  if ( direction === 'down' && scrolled && !brandsMenu && !aboutMenu && !servicesMenu ) headerClass += ' header--up'

  let brandSlug, btn, announcement = 'Edlyn Foods'
  if (brand) {
    switch (brand) {
      case 'bone':
        brandSlug = 'bone-roasters'
        announcement = 'Bone Roaster - Edlyn Foods'
        break
      case 'greenwheat':
        brandSlug = 'greenwheat-freekeh'
        announcement = 'Greenwheat Freekeh - Edlyn Foods'
        break
      case 'woods':
        brandSlug = 'woods'
        announcement = "Wood's - Edlyn Foods"
        break
      default:
        brandSlug = brand
    }
  }

  return (
    <>
      <div className="announcement-bar">
        <p>We’re celebrating 95 years of the company that brought you {announcement}. <Link to="/1929/">Explore our history</Link></p>
      </div>
      <header className={headerClass}>
        <div className="header__inner">
          <nav className="header__nav header__nav--left">
            <ul>
              {brand && <li><Link to={`/${brandSlug}/our-products/`}>Products</Link></li>}
              {brand && <li><Link to={`/${brandSlug}/recipes/`}>Recipes</Link></li>}
              {!brand &&
                <li onMouseEnter={openExpertiseMenu} onMouseLeave={closeExpertiseMenu}>
                  {brand === "edlyn" && <Link to="/edlyn/our-products/">Products</Link>}
                  <button>Our Expertise</button>
                  <div className={`header__expertise${servicesMenu ? " active" : ""}`}>
                    <ul>
                      <li>
                        <Link to="/food-service/" onClick={() => closeExpertiseMenu()}>
                          <span><ChefHat /></span>
                          <div>
                            <h5>Food Service</h5>
                            <p>Committed to producing the very best foodservice products for over 95 years.</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/custom-products/" onClick={() => closeExpertiseMenu()}>
                          <span><Coffee /></span>
                          <div>
                            <h5>Custom Products</h5>
                            <p>We specialise in the development and distribution of unique and innovative products.</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/international/" onClick={() => closeExpertiseMenu()}>
                          <span><International /></span>
                          <div>
                            <h5>International</h5>
                            <p>Our products are used by many of the world's finest restaurants and resorts.</p>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              }
              <li onMouseEnter={openBrandMenu} onMouseLeave={closeBrandMenu}>
                <button className="header__brands-top">Brands</button>
                <div className={`header__brands ${brandsMenu ? " active" : ""}`}>
                  <div className="header__brand">
                    <Link className="header__brand-logo edlyn" to="/edlyn/" onClick={() => closeBrandMenu()}>
                      <EdlynLogo />
                    </Link>
                    <div className="header__brand-content">
                      <p>A diverse range of foodservice products designed for ease of use and unwavering
                        consistency.</p>
                      <AnimationButton className={`btn btn--animation`} to="/edlyn/" onClick={() => closeBrandMenu()}>Visit
                        Edlyn Foods <RightArrow /></AnimationButton>
                    </div>
                  </div>
                  <div className="header__brand">
                    <Link className="header__brand-logo woods" to="/woods/" onClick={() => closeBrandMenu()}>
                      <WoodsBasicLogo />
                    </Link>
                    <div className="header__brand-content">
                      <p>For over 35 years Wood’s have created Australia’s finest range of award winning condiments.</p>
                      <AnimationButton className={`btn btn--animation`} to="/woods/" onClick={() => closeBrandMenu()}>Visit
                        Wood’s <RightArrow /></AnimationButton>
                    </div>
                  </div>
                  <div className="header__brand">
                    <Link className="header__brand-logo bone" to="/bone-roasters/" onClick={() => closeBrandMenu()}>
                      <BoneLogo />
                    </Link>
                    <div className="header__brand-content">
                      <p>Precisely crafted, award winning stocks and jus that elevate the flavour of every dish.</p>
                      <AnimationButton className={`btn btn--animation`} to="/bone-roasters/"
                                       onClick={() => closeBrandMenu()}>Visit Bone
                        Roasters <RightArrow /></AnimationButton>
                    </div>
                  </div>
                  <div className="header__brand">
                    <Link className="header__brand-logo greenwheat" to="/greenwheat-freekeh/"
                          onClick={() => closeBrandMenu()}>
                      <GreenWheatLogo />
                    </Link>
                    <div className="header__brand-content">
                      <p>Greenwheat Freekeh grain, grown and produced in South Australia.</p>
                      <AnimationButton className={`btn btn--animation`} to="/greenwheat-freekeh/"
                                       onClick={() => closeBrandMenu()}>Visit
                        Greenwheat <RightArrow /></AnimationButton>
                    </div>
                  </div>
                  {brand &&
                    <div className="header__brand-corporate"><p>For more information about our parent company, visit
                      the <Link to="/" onClick={() => closeBrandMenu()}>Edlyn Corporate Site</Link>.</p></div>}
                </div>
              </li>
              { !brand && <li><Link to='/1929/'>95 Years</Link></li> }
            </ul>
          </nav>
          <div className={`header__logo header__logo--${brand}`}>
            <Link to={
              (() => {
                switch (brand) {
                  case "edlyn":
                    return "/edlyn/"
                  case "woods":
                    return "/woods/"
                  case "bone":
                    return "/bone-roasters/"
                  case "greenwheat":
                    return "/greenwheat-freekeh/"
                  default:
                    return "/"
                }
              })()
            } {...props}>
              <div className="edlyn-1929">
                {
                  (() => {
                    switch (brand) {
                      case "woods":
                        return
                      case "bone":
                        return
                      case "greenwheat":
                        return
                      default:
                        return <Edlyn1929 />
                    }
                  })()
                }
              </div>
              {
                (() => {
                  switch (brand) {
                    case "woods":
                      return <WoodsLogo />
                    case "bone":
                      return (
                        <div className="bone--logo">
                          <BoneLogo />
                          <BoneBasicLogo color="#E9E5DB" />
                        </div>
                      )
                    case "greenwheat":
                      return <GreenWheatLogo />
                    default:
                      return <EdlynLogo />
                  }
                })()
              }
              <div className="edlyn-2024">
                {
                  (() => {
                    switch (brand) {
                      case "woods":
                        return
                      case "bone":
                        return
                      case "greenwheat":
                        return
                      default:
                        return <Edlyn2024 />
                    }
                  })()
                }
              </div>
              <div className="edlyn-mobile">
                {
                  (() => {
                    switch (brand) {
                      case "woods":
                        return
                      case "bone":
                        return
                      case "greenwheat":
                        return
                      default:
                        return <EdlynMobileYears />
                    }
                  })()
                }
              </div>
            </Link>
          </div>
          <nav className="header__nav header__nav--right">
            {brand &&
              <ul>
                <li><Link to={`/find-a-distributor/?brand=${brand}`}>Distributors</Link></li>
                <li><Link to="/">Corporate</Link></li>
                <li><Link to={`/${brandSlug}/contact`}>Contact</Link></li>
              </ul>
            }
            {!brand &&
              <ul>
                <li onMouseEnter={openNewsMenu} onMouseLeave={closeNewsMenu}>
                  <Link to="/news/">Media</Link>
                  <div className={`header__news${newsMenu ? " active" : ""}`}>
                    <ul>
                      <li>
                        <Link to="/news/?category=Edlyn%20News" onClick={() => closeNewsMenu()}>
                          <span><News /></span>
                          <div>
                            <h5>News</h5>
                            <p>The latest announcements, behind the scenes action and insights into Edlyn HQ.</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/recipes/" onClick={() => closeNewsMenu()}>
                          <span><Recipes /></span>
                          <div>
                            <h5>Recipes</h5>
                            <p>Get inspired with our delicious recipes that have been designed to pair with Edlyn’s
                              range of products.</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/news/?category=Product%20Catalogues" onClick={() => closeNewsMenu()}>
                          <span><Catalogues /></span>
                          <div>
                            <h5>Catalogues</h5>
                            <p>View and download the latest catalogues containing all our product information.</p>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li onMouseEnter={openAboutMenu} onMouseLeave={closeAboutMenu}>
                  <Link to="/our-story/">About</Link>
                  <div className={`header__about${aboutMenu ? " active" : ""}`}>
                    <ul>
                      <li>
                        <Link to="/our-story/" onClick={() => closeAboutMenu()}>
                          <span><Story /></span>
                          <div>
                            <h5>Our Story</h5>
                            <p>Edlyn have been committed to creating award winning products and brands since 1929.</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/our-team/" onClick={() => closeAboutMenu()}>
                          <span><Team /></span>
                          <div>
                            <h5>Edlyn Team</h5>
                            <p>Our people are our most important asset, they are the reason we are successful in what we
                              do and how we do it.</p>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/careers/" onClick={() => closeAboutMenu()}>
                          <span><Careers /></span>
                          <div>
                            <h5>Work at Edlyn</h5>
                            <p>Join a culture that embraces innovation and is driven by a unique blend of people.</p>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li><Link to="/contact-us/">Contact</Link></li>
              </ul>
            }
          </nav>
          <button onClick={() => setOffCanvas(!offCanvas)}
                  className={offCanvas ? "header__hamburger active" : "header__hamburger"}>
            <span className="lines"></span>
          </button>
        </div>
      </header>

      <div
        className={`off-canvas ${offCanvas ? "active" : ""} ${offCanvasMenu.menu[1] > 0 ? "off-canvas__menu--active" : ""} off-canvas--${brand}`}>
        <div className="off-canvas__inner">
          <nav className="off-canvas__nav">
            <ul>
              {brand && <li><Link to={`/${brandSlug}/our-products/`} {...props}>Products</Link></li>}
              {brand && <li><Link to={`/${brandSlug}/recipes/`} {...props}>Recipes</Link></li>}
              {!brand &&
                <li onMouseEnter={openExpertiseMenu} onMouseLeave={closeExpertiseMenu}>
                  <Link onClick={() => openOffCanvasMenu(0)}>Our Expertise <ChevronRight color="#19419E" /></Link>
                  <ul className={`off-canvas__dropdown ${offCanvasMenu.menu[0] ? 'active' : ''}`}>
                    <div className="off-canvas__close">
                      <button onClick={() => closeOffCanvasMenu()}><ChevronLeft color="#19419E" />Back</button>
                    </div>
                    <li>
                      <Link {...props} to="/food-service/" onClick={() => closeMenu()}>
                        <span><ChefHat /></span>
                        <div>
                          <h5>Food Service</h5>
                          <p>Committed to producing the very best foodservice products for over 95 years.</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link {...props} to='/custom-products/' onClick={() => closeMenu()}>
                        <span><Coffee /></span>
                        <div>
                          <h5>Custom Products</h5>
                          <p>We specialise in the development and distribution of unique and innovative products.</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link {...props} to='/international/' onClick={() => closeMenu()}>
                        <span><International /></span>
                        <div>
                          <h5>International</h5>
                          <p>Our products are used by many of the world's finest restaurants and resorts.</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </li>
              }
              <li onMouseEnter={openBrandMenu} onMouseLeave={closeBrandMenu}>
                <Link onClick={() => openOffCanvasMenu(1)}>Brands <ChevronRight color='#19419E' /></Link>
                <ul className={`header__brands ${offCanvasMenu.menu[1] ? 'active' : ''}`}>
                  <div className='off-canvas__close'>
                    <button onClick={() => closeOffCanvasMenu()}><ChevronLeft color='#19419E' />Back</button>
                  </div>
                  <li className='header__brand edlyn'>
                    <Link to='/edlyn/' onClick={() => closeMenu()}>
                      <EdlynLogo />
                      <button className='btn btn--border'><RightArrow color='#ffffff' /></button>
                    </Link>
                  </li>
                  <li className='header__brand wood'>
                    <Link to='/woods/' onClick={() => closeMenu()}>
                      <WoodsBasicLogo />
                      <button className='btn btn--border'><RightArrow color='#ffffff' /></button>
                    </Link>
                  </li>
                  <li className='header__brand bone'>
                    <Link to='/bone-roasters/' onClick={() => closeMenu()}>
                      <BoneLogoWhite />
                      <button className='btn btn--border'><RightArrow color='#ffffff' /></button>
                    </Link>
                  </li>
                  <li className='header__brand greenwheat'>
                    <Link to='/greenwheat-freekeh/' onClick={() => closeMenu()}>
                      <GreenWheatLogo />
                      <button className='btn btn--border'><RightArrow color='#ffffff' /></button>
                    </Link>
                  </li>
                </ul>
              </li>
              { !brand && <li><Link to='/1929/'>95 Years</Link></li> }
              {brand &&
                <li><Link to={`/find-a-distributor/?brand=${brand}`} onClick={() => closeMenu()}>Distributors</Link>
                </li>}
              {brand && <li><Link to='/' onClick={() => closeMenu()}>Corporate</Link></li>}
              {brand && <li><Link to={`/${brandSlug}/contact`} onClick={() => closeMenu()}>Contact</Link></li>}
              {!brand &&
                <li>
                  <Link onClick={() => openOffCanvasMenu(2)}>Media <ChevronRight color='#19419E' /></Link>
                  <ul className={`off-canvas__dropdown ${offCanvasMenu.menu[2] ? 'active' : ''}`}>
                    <div className="off-canvas__close">
                      <button onClick={() => closeOffCanvasMenu()}><ChevronLeft color="#19419E" />Back</button>
                    </div>
                    <li><Link {...props} to="/news/?category=Edlyn%20News" onClick={() => closeMenu()}>
                      <span><News /></span>
                      <div>
                        <h5>News</h5>
                        <p>The latest announcements, behind the scenes action and insights into Edlyn HQ.</p>
                      </div>
                    </Link></li>
                    <li><Link {...props} to='/recipes/' onClick={() => closeMenu()}>
                      <span><Recipes /></span>
                      <div>
                        <h5>Recipes</h5>
                        <p>Get inspired with our delicious recipes that have been designed to pair with Edlyn’s range of
                          products.</p>
                      </div>
                    </Link></li>
                    <li><Link {...props} to='/news/?category=Product%20Catalogues' onClick={() => closeMenu()}>
                      <span><Catalogues /></span>
                      <div>
                        <h5>Catalogues</h5>
                        <p>View and download the latest catalogues containing all our product information.</p>
                      </div>
                    </Link></li>
                  </ul>
                </li>
              }
              {!brand &&
                <li>
                  <Link onClick={() => openOffCanvasMenu(3)}>About <ChevronRight color='#19419E' /></Link>
                  <ul className={`off-canvas__dropdown ${offCanvasMenu.menu[3] ? 'active' : ''}`}>
                    <div className="off-canvas__close">
                      <button onClick={() => closeOffCanvasMenu()}><ChevronLeft color="#19419E" />Back</button>
                    </div>
                    <li><Link {...props} to="/our-story/" onClick={() => closeMenu()}>
                      <span><Story /></span>
                      <div>
                        <h5>Our Story</h5>
                        <p>Edlyn have been committed to creating award winning products and brands since 1929.</p>
                      </div>
                    </Link></li>
                    <li><Link {...props} to='/our-team/' onClick={() => closeMenu()}>
                      <span><Team /></span>
                      <div>
                        <h5>Edlyn Team</h5>
                        <p>Our people are our most important asset, they are the reason we are successful in what we do
                          and how we do it.</p>
                      </div>
                    </Link></li>
                    <li><Link {...props} to='/careers/' onClick={() => closeMenu()}>
                      <span><Careers /></span>
                      <div>
                        <h5>Work at Edlyn</h5>
                        <p>Join a culture that embraces innovation and is driven by a unique blend of people.</p>
                      </div>
                    </Link></li>
                  </ul>
                </li>
              }
              {!brand && <li><Link to='/contact-us/' onClick={() => closeMenu()}>Contact</Link></li>}
            </ul>
          </nav>
          {brand && <div className='off-canvas__brand-corporate'><p>For more information about our parent company, visit
            the <Link to='/' onClick={() => closeMenu()}>Edlyn Corporate Site</Link>.</p></div>}
        </div>
      </div>
    </>
  )
}

export default Header
