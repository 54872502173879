exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-bone-page-js": () => import("./../../../src/templates/bonePage.js" /* webpackChunkName: "component---src-templates-bone-page-js" */),
  "component---src-templates-bone-product-js": () => import("./../../../src/templates/boneProduct.js" /* webpackChunkName: "component---src-templates-bone-product-js" */),
  "component---src-templates-edlyn-page-js": () => import("./../../../src/templates/edlynPage.js" /* webpackChunkName: "component---src-templates-edlyn-page-js" */),
  "component---src-templates-edlyn-product-js": () => import("./../../../src/templates/edlynProduct.js" /* webpackChunkName: "component---src-templates-edlyn-product-js" */),
  "component---src-templates-greenwheat-page-js": () => import("./../../../src/templates/greenwheatPage.js" /* webpackChunkName: "component---src-templates-greenwheat-page-js" */),
  "component---src-templates-greenwheat-product-js": () => import("./../../../src/templates/greenwheatProduct.js" /* webpackChunkName: "component---src-templates-greenwheat-product-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-recipe-js": () => import("./../../../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-woods-page-js": () => import("./../../../src/templates/woodsPage.js" /* webpackChunkName: "component---src-templates-woods-page-js" */),
  "component---src-templates-woods-product-js": () => import("./../../../src/templates/woodsProduct.js" /* webpackChunkName: "component---src-templates-woods-product-js" */)
}

