import React from "react";
import {Link} from "../../../../plugins/gatsby-plugin-atollon";

const AnimationButton = (props) => {

  if (props.form) {
    return  (
      <button {...props} className={props.className}>
        <div className="pointer-event">
          <div className="pointer-event__follower"></div>
        </div>
        <span>{props.children}</span>
      </button>
    )
  }

  return (
    <Link className={props.className} to={props.to} onClick={props.onClick}>
      <div className="pointer-event">
      <div className='pointer-event__follower'></div>
            </div>
            <span>{props.children}</span>
        </Link>
    )
}

export default AnimationButton