import React from 'react'
import {AtollonProvider} from "../../plugins/gatsby-plugin-atollon";
import layoutQuery from "../hooks/use-layout-query";

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

import Lenis from "@studio-freight/lenis";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Layout = (props) => {

    const globalData = layoutQuery()

    React.useEffect(() => {
        const lenis = new Lenis()
        lenis.on('scroll', (e) => {})
        lenis.on('scroll', ScrollTrigger.update)
        gsap.ticker.add((time)=>{
            lenis.raf(time * 1000)
        })
        gsap.ticker.lagSmoothing(0)
    }, []);

    return (
        <AtollonProvider {...props} globalData={globalData}>
            <Header header={props.pageContext.header} brand={props.pageContext.brand} />
            <main className={`main--${props.pageContext.brand}`}>
                { props.children }
            </main>
            <Footer brand={props.pageContext.brand} />
        </AtollonProvider>
    )
}

export default Layout
